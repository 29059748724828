import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import MapCard from './MapCard';

function Home() {
    const navigate = useNavigate();
    return (
        <div className="dashboard">
            <div className='home-title'>Inspire Home</div>
            <div className='home-cards card-headers' onClick={() => navigate('/movies')}>Movies</div>
            <div className='home-cards'>
                <MapCard />
            </div>
        </div>
    );
}

export default Home;
