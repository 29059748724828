import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import Home from './Home';
import { BACKEND_IP } from '../stringMaster';

const Login = () => {
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${BACKEND_IP}/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }), // Only send the password
      });

      const data = await response.json();

      if (data.success) {
        setIsLoggedIn(true);
        navigate('/home');
      } else {
        setIsLoggedIn(false);
        alert('Invalid password');
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  return (
    <div>
      {isLoggedIn ? (
        <Home />
      ) : (
        <form onSubmit={handleLogin}>
          <div className="login-title">Inspire Home</div>
          <div className="login-card">
            <div className="login-text">Login</div>
            <div className="input-group">
              <label>
                Password:
                <input
                  type="password"
                  className="field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
            </div>
            <div className="input-group">
              <button
                style={{ width: '100px', height: '25px', marginTop: '20px' }}
                type="submit"
              >
                Login
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
