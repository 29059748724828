import React, { useState, useEffect } from 'react';
import { useNavigate, useHistory } from 'react-router-dom';
import './Movies.css';
import MediaPlayer from './MediaPlayer';
import { moviesData } from '../MovieData';
import { BACKEND_IP } from '../stringMaster';

const Movies = () => {
	const navigate = useNavigate();
	const [selectedMovie, setSelectedMovie] = useState(null);
    // Function to handle movie selection
    const handleMovieClick = (movie) => {
		setSelectedMovie(movie);
		// console.log(movie.id)
		fetch(BACKEND_IP+"/store-movie-id", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ movieId: movie.id })
        }).then(() => {
            navigate('/player');
        }).catch(error => {
            console.error('Error sending movie ID:', error);
        });
	};

	

	return (

	// 	<div>
	// 	<div className='movies-title'>Movies</div>
	// 	{selectedMovie ? (
	// 	  <MediaPlayer movieUrl={selectedMovie.videoUrl} />
	// 	) : (
	// 	  <div className="movies-grid">
	// 		{moviesData.map((movie) => (
	// 		  <div key={movie.id} className="movie-thumbnail" onClick={() => handleMovieClick(movie)}>
	// 			<img src={movie.thumbnailUrl} alt={movie.title} />
	// 			<div className='this-movie-title'>{movie.title}</div>
	// 		  </div>
	// 		))}
	// 	  </div>
	// 	)}
	//   </div>

	<div>
            <div className='movies-title'>Movies</div>
            <div className="movies-grid">
                {moviesData.map((movie) => (
                    <div key={movie.id} className="movie-thumbnail" onClick={() => handleMovieClick(movie)}>
                        <img src={movie.thumbnailUrl} alt={movie.title} />
                        <div className='this-movie-title'>{movie.title}</div>
                    </div>
                ))}
            </div>
        </div>

	//   <div>
	// 	<h1>Movies</h1>
	// 	{selectedMovie ? (
	// 	  <MediaPlayer movieUrl={url} />
	// 	) : (
	// 	  <div>
	// 		{/* Render movie thumbnails */}
	// 		<div onClick={() => handleMovieClick(moviesData)}>
	// 		  <img src="path-to-movie-thumbnail-1" alt="Movie 1" />
	// 		</div>
	// 		<div onClick={() => handleMovieClick("/home/andy/Documents/inspireCode/inspire_home_server/src/Movies/Jurassic_World_Fallen_Kingdom_2018_1080p/Jurassic_World_Fallen_Kingdom_2018_1080p.mp4")}>
	// 		  <img src="path-to-movie-thumbnail-2" alt="Movie 2" />
	// 		</div>
	// 		{/* Add more movie thumbnails as needed */}
	// 	  </div>
	// 	)}
	//   </div>
	);
  };
  
  export default Movies;

