import React from 'react';
import ReactPlayer from 'react-player';
import './MediaPlayer.css';

const MediaPlayer = ({ movieUrl }) => {
  return (
    <div className='react-player'>
      <ReactPlayer
        style={{margin:"auto"}}
        url={movieUrl}
        controls
        playing="true"
        width="80%"
        height="auto"
      />
    </div>
  );
};

export default MediaPlayer;
