import React, { useState, useEffect } from 'react';
import MediaPlayer from './MediaPlayer';
import { moviesData } from '../MovieData';
import { BACKEND_IP } from '../stringMaster';

const PlayerPage = () => {
  const [movieUrl, setMovieUrl] = useState(null);
  const [movieTitle, setMovieTitle] = useState(null);
  useEffect(() => {
    fetch(BACKEND_IP+"/get-movie-id")
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch movie ID');
        }
        console.log(response)
        return response.json();
      })
      .then((json) => {
        console.log(json);
        const movieId = json.movieId;
        // Find the movie object with the matching ID
        const movie = moviesData.find((movie) => movie.id === movieId);
        if (movie) {
            console.log(movie.id)
            console.log(movie.videoUrl)
            setMovieTitle(movie.title)
            setMovieUrl(movie.videoUrl); 
        } else {
          console.error('Movie not found with ID:', movieId);
        }
      })
      .catch((error) => {
        console.error('Error fetching movie ID:', error);
      });
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <div>
      <h1>Now Playing: {movieTitle}</h1>
      <h2>Grab some popcorn and Enjoy the movie!</h2>
      <MediaPlayer movieUrl={movieUrl} />
    </div>
  );
};

export default PlayerPage;
