import { BACKEND_IP } from './stringMaster';


export const moviesData = [
    { id: 1, title: 'Jurassic World', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/jw_2015_poster.jpg", videoUrl: BACKEND_IP+'/movies/Jurassic_World_2015_1080p.mp4' },
    { id: 2, title: 'Jurassic World: Fallen Kingdom', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/jw_fk_poster.jpg", videoUrl: BACKEND_IP+'/movies/Jurassic_World_Fallen_Kingdom_2018_1080p.mp4' },
    { id: 3, title: 'Jurassic World: Dominion', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/jw_dominion_poster.jpg", videoUrl: BACKEND_IP+'/movies/Jurassic_World_Dominion_2022_1080p.mp4' },
    { id: 4, title: 'Legally Blonde', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/legally_blonde_poster.jpg", videoUrl: BACKEND_IP+'/movies/Legally_Blonde_2001_1080p.mp4' },
    { id: 5, title: 'Clueless', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/clueless_poster.jpg", videoUrl: BACKEND_IP+"/movies/Clueless_1995_1080p.mp4" },
    { id: 6, title: '50 First Dates', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/50_first_dates_poster.jpg", videoUrl: BACKEND_IP+'/movies/50_First_Dates_2004_1080p.mp4' },
    { id: 7, title: 'Burlesque', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/burlesque_poster.jpg", videoUrl: BACKEND_IP+'/movies/Burlesque_2010_1080p.mp4' },
    { id: 8, title: 'Dirty Dancing Havana Nights', thumbnailUrl: BACKEND_IP+"/movies/thumbnails/dirty_dancing_havana_nights_poster.jpg", videoUrl: BACKEND_IP+"/movies/Dirty_Dancing_Havana_Nights_2004_1080p.mp4" },
    // Add more movie objects as needed
  ];
