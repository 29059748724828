import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import serverMapIcon from '../assets/images/server_map_waypoint.png'
import './MapCard.css'

// Function to create a new icon with the given size
const createIcon = (size) => {
  return new L.Icon({
    iconUrl: serverMapIcon,
    iconSize: size,
  });
};

const MapCard = () => {
  const serverLocation = [25.873022331234374, -80.27133522325182];
  const [zoomLevel, setZoomLevel] = useState(13);
  const [iconSize, setIconSize] = useState([100, 125]);

  // Update icon size based on zoom level
  useEffect(() => {
    const newSize = [zoomLevel * 10, zoomLevel * 12.5];
    setIconSize(newSize);
  }, [zoomLevel]);

  const DynamicMap = () => {
    useMapEvents({
      zoom: (event) => {
        setZoomLevel(event.target.getZoom());
      },
    });
    return null;
  };

  return (
    <div className="map-card">
      <div className="map-header-text">Map</div>
      <MapContainer center={serverLocation} zoom={zoomLevel} style={{ height: '300px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={serverLocation} icon={createIcon(iconSize)}>
          <Popup>Inspire Home Server</Popup>
        </Marker>
        <DynamicMap />
      </MapContainer>
    </div>
  );
};

export default MapCard;
